import React, { Component } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from "react-router-dom";

//Page
import './Asset/Css/font.css';
import './Asset/Css/Style.css';
import './Asset/Css/bootstrap.min.css';
import { Tooltip, Toast, Popover } from 'bootstrap';
import Home from './Theme/Template/Home';
import Login from './Theme/Template/Login';
import Register from './Theme/Template/Register';
import CreateWeb from './Theme/Template/CreateWeb';
import CreateWebFrorm from './Theme/Template/CreateWebFrorm';
import Profile from './Theme/Template/Profile';
import ChangePassword from './Theme/Template/ChangePassword';
import LichSuTaoWeb from './Theme/Template/LichSuTaoWeb';
import BuySource from './Theme/Template/BuySource';
import LichSuMuaSource from './Theme/Template/LichSuMuaSource';
import BuyDomain from './Theme/Template/BuyDomain';
import LichSuMuaMien from './Theme/Template/LichSuMuaMien';
import Naptien from './Theme/Template/Naptien';
import Napthe from './Theme/Template/Napthe';
import RutTien from './Theme/Template/RutTien';

class Routers extends Component {

    render() {
        return (
            <Router>
                <div>
                    <Switch>

                        <Route exact path="/">
                            <Home />
                        </Route>

                        <Route path="/dang-nhap">
                            <Login />
                        </Route>

                        <Route path="/dang-ky">
                            <Register />
                        </Route>

                        <Route exact path="/profile">
                            <Profile />
                        </Route>

                        <Route path="/profile/change-password">
                            <ChangePassword />
                        </Route>

                        <Route path="/profile/lich-su-tao-web">
                            <LichSuTaoWeb />
                        </Route>

                        <Route path="/profile/lich-su-mua-source">
                            <LichSuMuaSource />
                        </Route>

                        <Route path="/profile/lich-su-mua-mien">
                            <LichSuMuaMien />
                        </Route>

                        <Route exact path="/tao-web">
                            <CreateWeb />
                        </Route>

                        <Route path="/tao-web/theme-:id">
                            <CreateWebFrorm/>
                        </Route>

                        <Route exact path="/mua-source">
                            <BuySource />
                        </Route>

                        <Route exact path="/mua-mien">
                            <BuyDomain />
                        </Route>

                        <Route exact path="/nap-tien">
                            <Naptien />
                        </Route>

                        <Route exact path="/nap-tien/the-cao">
                            <Napthe />
                        </Route>

                        <Route exact path="/rut-tien">
                            <RutTien />
                        </Route>
                        
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default Routers;