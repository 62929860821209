import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Navbar from './../Component/Navbar';

class LichSuTaoWeb extends Component {
    constructor(props) {
        super(props);
        document.title = "Lịch sử tạo web";

        this.state = {
            'listds': [],
        }
    }

    componentDidMount(){
        var url = 'lichsutaoweb.php';
        var data = {};

        const cookies = new Cookies();

        var token = 'null';

        if (!cookies.get('token')) {
            token = 'null';
        } else {
            token = cookies.get('token');
        };


        const headers = { Authorization: `${token}` };


        axios.post (process.env.REACT_APP_API + url, data,  { headers })
        .then(response => {
            var listds = response.data.data.danhsachweb;
            this.setState({
                listds: listds,
            });

        });
    }

    render() {


        var listds = this.state.listds;
        var dem = -1;
        var listds = listds.map((data) =>
            <tr key={dem = dem + 1}>
              <th scope="row">{data.data.id}</th>
              <td>{data.data.taikhoanadmin}</td>
              <td>{data.data.matkhauadmin}</td>
              <td>{data.data.domain}</td>
              <td>{data.data.ngaytao}</td>
              <td>{data.data.hsd}</td>
              <td>
                {
                    data.status ? <button className="btn btn-success w-100pt" disabled> { data.message } </button> : <button className="btn btn-danger w-100pt" disabled> {data.message} </button>
                }
              </td>
            </tr>
        );

        return (
            <div>
                <Navbar />
                <div className="py-5"></div>
                <div className="py-4"></div>
                <div className="container">
                    <div className="bg-table rounded">
                        <table className="table table-light table-striped rounded table-hover table-bordered">
                        <thead>
                            <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Tài khoản Admin</th>
                            <th scope="col">Mật khẩu Admin</th>
                            <th scope="col">URL</th>
                            <th scope="col">Ngày tạo</th>
                            <th scope="col">HSD</th>
                            <th scope="col">Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                                { listds }
                        </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }
}

export default LichSuTaoWeb;