import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

class PostCard extends Component {
    render() {
        return (
            <div className="container">
                <Link to={this.props.to} className="thea">
                    <div className="py-3 rounded border text-light shadow metab">
                            <img src={this.props.img} className="thumb-1 rounded mb-4"></img>
                            <h4>{this.props.name}</h4>
                            <p className="text-light">{this.props.mota}</p>
                    </div>
                </Link>
            </div>
        );
    }
}

export default PostCard;