import React, { Component } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from "react-router-dom";

import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import { Redirect } from 'react-router';
import axios from 'axios';


import Navbar from './../Component/Navbar';
import Cover from './../../Asset/Image/cover.png';
import Avatar from './../../Asset/Image/avatar.png';
import Next from './../../Next';

class Profile extends Component {

    constructor(props) {
        super(props);
        document.title = "Profile";

        this.state = {
            'infouser': {
                'username':
                    <div className="spinner-border text-dark spinner-border-sm" role="status">
                        <span className="visually-hidden"></span>
                    </div>,
                'vnd':
                    <div className="spinner-border text-dark spinner-border-sm" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                ,
                'id': 
                <div className="spinner-border text-dark spinner-border-sm" role="status">
                    <span className="visually-hidden"></span>
                </div>,
                'nhomtaikhoan': 
                <div className="spinner-border text-dark spinner-border-sm" role="status">
                    <span className="visually-hidden"></span>
                </div>,
                'ngaydangky': 
                <div className="spinner-border text-dark spinner-border-sm" role="status">
                    <span className="visually-hidden"></span>
                </div>,
            },
        }
    }

    componentDidMount() {
        var url = 'checktoken.php';
        var data = {};

        // Check token đúng hay sai
        const cookies = new Cookies();

        var token = 'null';

        if (!cookies.get('token')) {
            token = 'null';
        } else {
            token = cookies.get('token');
        };

        const headers = { Authorization: `${token}` };

        axios.post(process.env.REACT_APP_API + url, data, { headers })
            .then(response => {
                var content = response.data;
                var data_api = content.data;
                if (content.status == false) {
                    cookies.remove("token");
                } else {
                    this.setState({
                        infouser: {
                            'username': data_api.infouser.username,
                            'vnd': data_api.infouser.vnd + 'đ',
                            'id': data_api.infouser.id,
                            'nhomtaikhoan': data_api.infouser.nhomtaikhoan,
                            'ngaydangky': data_api.infouser.ngaydangky,
                        }
                    });

                    this.setState({ showmenu: this.state.infouser['username'] + ' - ' + this.state.infouser['vnd'] + 'đ' });
                }

            })
            .catch(error => console.log(error));
    }

    render() {
        return (
            <div>
                <Next level="2" />
                <Navbar />
                <div className="py-5"></div>
                <div className="py-4"></div>
                <div className="container mb-5">
                    <div className="bg-main rounded">
                        <div className="cover">
                        <img src={Cover} className="w-100pt"></img>
                        </div>
                        <div className="text-center">
                            <img src={Avatar} className="avatar rounded-circle img-thumbnail border shadow"></img>
                        </div>
                        <h3 className="text-center text-bold upcase text-avatar mb-5">
                            <span className="px-2 text-light">
                                {
                                    this.state.infouser['username']
                                }                        

                            </span>
                        </h3>

                        <div className="py-3">
                            <div className="row">
                                <div className="col-12 col-md-4 mb-3">
                                    <div className="container">
                                        <Link to="/profile/lich-su-tao-web">
                                            <button className="btn btn-danger w-100pt mb-3">
                                                TRANG WEB ĐÃ TẠO
                                            </button>
                                        </Link>

                                        <Link to="/profile/lich-su-mua-source">
                                            <button className="btn text-light btn-info w-100pt mb-3">
                                                SOURCE CODE ĐÃ MUA
                                            </button>
                                        </Link>

                                        <Link to="/profile/lich-su-mua-mien">
                                            <button className="btn btn-warning text-light w-100pt mb-3">
                                            TÊN MIỀN ĐÃ MUA
                                            </button>
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-12 col-md-8 mb-3">
                                    <table className="table table-striped">
                                        <tbody>
                                            <tr>
                                                <th scope="row">ID của bạn:</th>
                                                <th><span className="c-font-uppercase">{this.state.infouser['id']}</span></th>
                                            </tr>
                                            <tr>
                                                <th scope="row">Tên tài khoản:</th>
                                                <th>{this.state.infouser['username']}</th>
                                            </tr>
                                            <tr>
                                                <th scope="row">Số dư tài khoản:</th>
                                                <td><b className="text-danger">{this.state.infouser['vnd']}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Nhóm tài khoản:</th>
                                                <td>{this.state.infouser['nhomtaikhoan'] }</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Ngày tham gia:</th>
                                                <td>
                                                    {this.state.infouser['ngaydangky']}
            </td>
                                            </tr>

                                            <tr>
                                                <th scope="row">Mật khẩu:</th>
                                                <td><Link to="/profile/change-password" className="thea"><b><i className="text-danger">****** (Đổi mật khẩu)</i></b></Link></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;