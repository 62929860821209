import React, { Component } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import swal from 'sweetalert';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router';

class CardSp2 extends Component {

    constructor(props){
        super(props);
        this.props = props;

        this.state = {
            load: false,
            next: false,
        }
    }

    Xacnhanmua = () => {

        swal("Xác nhận mua source code " + '' + this.props.name + '' + " với giá " + this.props.money + " VNĐ?", {
          buttons: {
            cancel: "Đóng",

            catch: {
              text: "Mua ngay",
              value: "catch",
            },

          },
        })
        .then((value) => {
          switch (value) {

            case "catch":
                this.setState({
                    load: true,
                }); 

                // Check token đúng hay sai
                const cookies = new Cookies();

                var token = 'null'

                if ( !cookies.get('token') ) {
                    token = 'null'
                } else {
                    token = cookies.get('token');
                };

                const headers = { Authorization: `${token}` };

                var url = 'muasource.php';
                var data = {
                    'idsource': this.props.id,
                };

                axios.post (process.env.REACT_APP_API + url, data, { headers } )
                .then(response => {
                    this.setState({
                        load: false,
                    }); 

                    var callback = response.data; 
                    
                    if ( callback['status'] == true ) {
                        var type = 'success';
                        this.setState({
                            next: true,
                        }); 
                    } else {
                        var type = 'error';
                    }

                    var message = callback['message'];


                    swal("Thông báo", message, type);
                })

          }
        });

    }

    render() {
        return (
            <div>
                {this.state.next ? <Redirect to="/profile/lich-su-mua-source" />  :  ''}
                <div className="border rounded bg-main shadow-sm mb-3">
                    <div className="">
                        <img src={this.props.img} className="w-100pt rounded-top border-bottom mb-3 img-thumb"></img>
                    </div>

                    <div className="text-center">
                        <p className="text-bold d-inline-block text-truncate text-hidden"><span className="text-uppercase">{this.props.name}</span> - {this.props.money}<i className="dshow">đ</i></p>
                        <div className="row container-fluid">
                            <div className="col-md-6 mb-3">
                                <a target="_bank" href={this.props.demo}><button className="btn btn-info text-light w-100pt no-border btn-buy rounded-bottom">
                                    Demo
                                </button></a>
                            </div>

                            <div className="col-md-6 mb-3">
                                <button onClick={this.Xacnhanmua} className="btn btn-danger w-100pt no-border btn-buy rounded-bottom">
                                    Mua
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default CardSp2;