import React, { Component } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

class CardSp extends Component {
    render() {
        return (
            <div>
                <div className="rounded bg-light shadow-sm mb-3">
                    <div className="">
                        <img src={this.props.img} className="border w-100pt rounded-top border-bottom mb-3 img-thumb"></img>
                    </div>

                    <div className="text-center">
                        <p className="text-uppercase text-bold d-inline-block text-truncate text-hidden">{this.props.name}</p>
                        <Link to={'/tao-web/theme-' + this.props.id}>
                            <button className="btn btn-danger w-100pt no-border btn-buy rounded-bottom">Tạo ngay</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default CardSp;