import Routers from './Routers';
import Nhacnen from './Asset/Audio/nhacnen.mp3';

console.log('Developer by ID Thiên Ân');

//var audio = new Audio(Nhacnen);
//audio.play();

function App() {
  return (
    <div className="App">
      <Routers />
    </div>
  );
}

export default App;