import React, { Component } from 'react';
import Navbar from './../Component/Navbar';
import axios from 'axios';
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import { Redirect } from 'react-router';

class BuyDomain extends Component {

    constructor(props){
        super(props);
        document.title = "Mua miền";

        this.state = {
            'name_domain': '',
            'select_type_domain': '',
            'listds': [],
            'set_mount': 0,
            'next': false,
            'load': false,
            'hsd': 0,
            'dns': '',
            'list_hsd': [],
            'demo': 0,
        }

        this.ChangeTypeDomain = this.ChangeTypeDomain.bind(this);
        this.MuaMien = this.MuaMien.bind(this);
        this.ChangeNameDomain = this.ChangeNameDomain.bind(this);
        this.ChangeHSD = this.ChangeHSD.bind(this);
        this.ChangeDNS = this.ChangeDNS.bind(this);
        
    }

    ChangeTypeDomain(event){

        this.setState({
            select_type_domain: event.target.value,
        });

        if ( event.target.value == 0 ) {

            this.setState({
                demo: 0,
            });

        } else {

            this.setState({
                demo: this.state.listds[ parseInt(event.target.value) - 1 ].gia,
            });

        }

    }

    ChangeNameDomain(event){
        this.setState({
            name_domain: event.target.value,
        });
    }

    ChangeHSD(event){
        this.setState({
            hsd: event.target.value,
        });
    }

    ChangeDNS(event){
        this.setState({
            dns: event.target.value,
        });
    }

    componentDidMount(){
        var url = 'get_type_domain.php';
        var data = {};

        axios.post (process.env.REACT_APP_API + url, data)
        .then(response => {
            var type_domain = response.data.data.type_domain;
            this.setState(
                {
                    listds: type_domain,
                }
            );
        });


        var url = 'get_hsd_domain.php';
        var data = {};

        axios.post (process.env.REACT_APP_API + url, data)
        .then(response => {
            var hsd_domain = response.data.data.hsd_domain;
            this.setState(
                {
                    list_hsd: hsd_domain,
                }
            );
        });
    }

    MuaMien(event) {
        this.setState({load: true});
        var domain = {
            'name': this.state.name_domain,
            'type': this.state.select_type_domain,
            'hsd': this.state.hsd,
            'dns': this.state.dns,
        };

        const cookies = new Cookies();

        var token = 'null';
    
        if ( !cookies.get('token') ) {
            token = 'null'
        } else {
            token = cookies.get('token');
        };

       //
       var url = 'muamien.php';
       var data = domain;
       const headers = { Authorization: `${token}` };

       axios.post (process.env.REACT_APP_API + url, data, { headers } )
       .then(response => {
            this.setState({load: false});
           var content = response.data;

            if (content.status == true){
                var type = 'success';
                swal("Thông báo", content.message, type);
                this.setState(
                    {
                        next: true,
                    }
                );

            } else {
                var type = 'error';
                swal("Thông báo", content.message, type);
            }
       });

        event.preventDefault();
    }

    render() {

        var dem = 0;
        var listds = this.state.listds;

        var listds = listds.map((data) =>
            <option key={dem} value={dem = dem + 1}>{ data.type } ({ data.gia } VNĐ)</option>
        );

        var listds2 = this.state.list_hsd;
        
        var dem2 = 0;
        var listds2 = listds2.map((data) =>
            <option key={data.value} value={data.value}>{data.name}</option>
        );

        return (
            <div>
                {
                    this.state.next  ? <Redirect to="/profile/lich-su-mua-mien" /> : ''
                }
                <Navbar />
                <div className="py-5"></div>
                <div className="py-3"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-3"></div>
                        <div className="col-12 col-md-6">
                            <div className="bg-light py-3 rounded">
                                <h3 className="fw-bold text-danger font-monospace container">MUA MIỀN</h3>
                                <hr />
                                <form className="container" onSubmit={this.MuaMien}>
                                    <label className="mb-2">Nhập tên miền:</label>
                                    <div>
                                        <input value={this.state.name_domain} onChange={this.ChangeNameDomain} className="form-control rounded-pill mb-3" placeholder="Nhập tên miền" />
                                        <label className="mb-2">Chọn loại miền:</label>
                                        <select className="form-control rounded-pill mb-3" value={this.state.select_type_domain} onChange={this.ChangeTypeDomain}>
                                            <option value="0">Chọn loại miền</option>
                                            { listds }
                                        </select>
                                        <label className="mb-2">Chọn hạn sử dụng:</label>
                                        <select className="form-control rounded-pill mb-3" value={this.state.hsd} onChange={this.ChangeHSD}>
                                            <option value="0">Chọn hạn sử dụng:</option>
                                            { listds2 }
                                        </select>
                                        <label className="mb-2">Nameserver trỏ (Viết sai lập tức inbox admin để hỗ trợ):</label>
                                        <textarea rows="10" className="form-control mb-3" placeholder="Nameserver trỏ (Viết sai lập tức inbox admin để hỗ trợ):" onChange={this.ChangeDNS}>{this.state.dns}</textarea>
                                    </div>
                                    {
                                        this.state.load
                                        ?
                                        <button className="btn btn-danger w-100pt rounded-pill py-2" disabled>
                                            Mua ngay - { this.state.demo * parseInt(this.state.hsd) } VNĐ

                                            <span className="px-1"></span>
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>

                                        </button>
                                        :
                                        <button className="btn btn-danger w-100pt rounded-pill py-2">
                                            Mua ngay - { this.state.demo * parseInt(this.state.hsd) } VNĐ
                                        </button>
                                    }
                                </form>
                            </div>
                        </div>
                        <div className="col-12 col-md-3"></div>
                    </div>
                </div>
            </div>
        );
    }

}

export default BuyDomain;