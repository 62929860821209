import React, { Component } from 'react';

import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import axios from 'axios';
import { Redirect } from 'react-router';

import Next from './../../Next';
import Navbar from './../Component/Navbar';

class ChangePassword extends Component {

    constructor(props){
        super(props);
        this.props = props;
        document.title = "Đổi mật khẩu";
        const cookies = new Cookies();

        this.state = {
            'passworldold': '',
            'passwordnew': '',
            'repasswordnew': '',
            'load': false,
        };

        this.ChangePassOld = this.ChangePassOld.bind(this);
        this.ChangePassNew = this.ChangePassNew.bind(this);
        this.ChangeRepassnew = this.ChangeRepassnew.bind(this);
        this.DoiMatKhau = this.DoiMatKhau.bind(this);

    }


    ChangePassOld(event){
        this.setState({passworldold: event.target.value});
    }

    ChangePassNew(event){
        this.setState({passwordnew: event.target.value});
    }

    ChangeRepassnew(event){
        this.setState({repasswordnew: event.target.value});
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    DoiMatKhau(event){

        this.setState({load: true});

        var url = 'doimatkhau.php';        
        var data = {
            'passwordold': this.state.passworldold,
            'passwordnew': this.state.passwordnew,
            'repasswordnew': this.state.repasswordnew,
            'changesuccess': false,
        };

        const cookies = new Cookies();
    
        var token = 'null'
    
        if ( !cookies.get('token') ) {
            token = 'null'
        } else {
            token = cookies.get('token');
        };
        
        const headers = { Authorization: `${token}` };
    
        axios.post (process.env.REACT_APP_API + url, data, { headers } )
        .then(response => {
          this.setState({load: false});

          var content = response.data;

          if (content.status == true){
              var type = 'success';
              swal("Thông báo", content.message, type);

              this.sleep(1000).then(() => {
                cookies.remove("token");
                this.setState({changesuccess: true});
               });
          } else {
              var type = 'error';
              swal("Thông báo", content.message, type);
          }



        })
        .catch(error => console.log(error));

        event.preventDefault();
    }

    render() {

            var noload = <button type="submit" className="btn btn-danger w-100pt">Đổi mật khẩu</button>;
            var haveload = <button type="submit" className="btn btn-danger w-100pt" disabled>
                Đổi mật khẩu
                <span className="px-1"></span>
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only"></span>
                </div>
            </button>;


        return (
            <div>
                {this.state.changesuccess ? <Redirect to="/dang-nhap" />  :  ''}
                <Next level="2" />
                <Navbar />
                <div className="py-5"></div>
                <div className="py-5"></div>
                <div className="container">
                    <div className="bg-main py-5 rounded container">
                        <div className="row">
                            <div className="col-12 col-md-2"></div>
                            <div className="col-12 col-md-8">
                                <form onSubmit={this.DoiMatKhau}>
                                    <label>Nhập mật khẩu hiện tại: </label>
                                    <input className="form-control mb-4" type="password" placeholder="Nhập mật khẩu hiện tại" value={this.state.passworldold} onChange={this.ChangePassOld}></input>
                                    <label>Nhập mật khẩu mới:</label>
                                    <input className="form-control mb-4" type="password" placeholder="Nhập mật khẩu mới" value={this.state.passworldnew} onChange={this.ChangePassNew}></input>
                                    <label>Nhập lại mật khẩu mới:</label>
                                    <input className="form-control mb-4" type="password" placeholder="Nhập mật khẩu mới" value={this.state.repassworldnew} onChange={this.ChangeRepassnew}></input>
                                    {this.state.load ? haveload  :  noload}
                                </form>
                            </div>
                            <div className="col-12 col-md-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword;