import React, { Component } from 'react';
import Logo from './../../Asset/Image/logo.png';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Cookies from 'universal-cookie';
import $ from 'jquery';
import swal from 'sweetalert';
import { Redirect } from 'react-router';
import axios from 'axios';

class Navbar extends Component {
    
    constructor(props){
        super(props);
        this.DangXuat = this.DangXuat.bind(this);

        this.state = {
            'outsuccess': false,
            'infouser': {
                'username': '',
                'vnd': '',
            },
            'showmenu': 
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden"></span>
                </div>
            ,
        } 
    }

    NoUserNav(){
        return (
            <div>
                <Link to="/dang-ky">
                    <button className="custom-btn btn-8">
                        <span>
                            Đăng ký
                        </span>
                    </button>
                </Link>

                <span className="px-1"></span>
                
                <Link to="/dang-nhap">
                    <button className="custom-btn btn-13">
                        <span>
                            Đăng nhập
                        </span>
                    </button>
                </Link>
            </div>
        );
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    DangXuat(eventt){
        const cookies = new Cookies();
        cookies.remove("token");

        swal("Thông báo", "Đăng xuất thành công", "success");
        this.sleep(1000).then(() => {
            this.setState({outsuccess: true});
        });
    }

    UserNav(){
        const cookies = new Cookies();
        return (
            <div>
                <Link to="/profile">
                    <button className="btn btn-danger">
                        <span className="upcase text-bold">
                            {  this.state.showmenu }
                        </span>
                    </button>
                </Link>

                <span className="px-1"></span>
                
                    <button className="custom-btn btn-13" onClick={this.DangXuat}>
                        <span>
                            Đăng xuất
                        </span>
                    </button>
            </div>
        )
    }

    componentDidMount(){
        var url = 'checktoken.php';
        var data = {};

        // Check token đúng hay sai
        const cookies = new Cookies();

        var token = 'null';
    
        if ( !cookies.get('token') ) {
            token = 'null';
        } else {
            token = cookies.get('token');
        };
        
        const headers = { Authorization: `${token}` };
    
        axios.post (process.env.REACT_APP_API + url, data, { headers } )
        .then(response => { 
            var content = response.data;
            var data_api = content.data;
            if ( content.status == false ) {
                cookies.remove("token");
            } else {
                this.setState({infouser: {
                    'username': data_api.infouser.username,
                    'vnd': data_api.infouser.vnd,
                }});

                this.setState({showmenu: this.state.infouser['username'] + ' - ' + this.state.infouser['vnd'] + 'đ'});
            }
    
        })
        .catch(error => console.log(error));
    }

    render() {
        const cookies = new Cookies();

        return (
            <div>
                {this.state.outsuccess ? <Redirect to="" />  :  ''}
                <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-lg p-3 mb-5 fixed-top">
                    <div className="container">
                        <Link className="navbar-brand" to="/">
                            <img src={Logo} className="logo"></img>
                        </Link>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">Trang chủ</Link>
                            </li>
                            <li className="nav-item">   
                                <Link className="nav-link" to="/nap-tien">Nạp tiền</Link>
                            </li>
                            
                            { cookies.get('token') ?
                            <li className="nav-item">   
                                <Link className="nav-link" to="/rut-tien">Rút tiền</Link>
                            </li>
                            : '' }
                        </ul>
                            <div className="float-right">

                                
                                { cookies.get('token') ? this.UserNav() : this.NoUserNav() }

                            </div>

                        </div>

                    </div>
                </nav>
            </div>
        );
    }
}

export default Navbar;