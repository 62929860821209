import React, { Component } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import axios from 'axios';
import { Redirect } from 'react-router';


import Next from './../../Next';
import Navbar from './../Component/Navbar';

class Login extends Component {
    constructor(props){
        super(props);
        document.title = "Đăng nhập";
        const cookies = new Cookies();

        cookies.remove("token");

        this.state = {
            'username': '',
            'password': '', 
            'load': false,
            'loginsuccess': false,
        }

        this.ChangeUsername = this.ChangeUsername.bind(this);
        this.ChangePassword = this.ChangePassword.bind(this);
        this.Dangnhap = this.Dangnhap.bind(this);
    }


    ChangeUsername(event){
        this.setState({username: event.target.value});
    }

    ChangePassword(event){
        this.setState({password: event.target.value});
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }  

    Dangnhap(event){
        this.setState({load: true});

        var url = 'dang-nhap.php';        
        var data = {
            'username': this.state.username,
            'password': this.state.password,
        };

        const cookies = new Cookies();
    
        var token = 'null'
    
        if ( !cookies.get('token') ) {
            token = 'null'
        } else {
            token = cookies.get('token');
        };
        
        const headers = { Authorization: `${token}` };
    
        axios.post (process.env.REACT_APP_API + url, data, { headers } )
        .then(response => {
          this.setState({load: false});

          var content = response.data;

          if (content.status == true){
              var type = 'success';
              var token = content.data.token;
              cookies.set('token', token); 
              swal("Thông báo", content.message, type);
              this.sleep(1000).then(() => { 
                this.setState({loginsuccess: true});
               });
          } else {
              var type = 'error';
              cookies.set('token', ''); 
              swal("Thông báo", content.message, type);
          }

        })
        .catch(error => console.log(error));
        

        event.preventDefault();
    }

    render() {

        var noload = <button type="submit" className="btn btn-danger w-100pt">Đăng nhập</button>;
        var haveload = <button type="submit" className="btn btn-danger w-100pt" disabled> 
            Đăng nhập 
            <span className="px-1"></span>   
            <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
            </div>
        </button>;

        return (
            <div>
                {this.state.loginsuccess ? <Redirect to="/" />  :  ''}
                <Navbar />

                <div className="py-5"></div>
                <div className="py-5"></div>

                <div className="container">
                    <div className="bg-main py-4 rounded container">
                        <form onSubmit={this.Dangnhap}>

                            <label>Tài khoản:</label>
                            <input className="form-control rounded-pill inputx mb-4" placeholder="Nhập tài khoản" value={this.state.username} onChange={this.ChangeUsername}></input>

                            <label>Mật khẩu:</label>
                            <input type="password" className="form-control rounded-pill inputx mb-4" placeholder="Nhập mật khẩu" value={this.state.password} onChange={this.ChangePassword}></input>

                            {this.state.load ? haveload  :  noload}

                            <div className="py-3"></div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;