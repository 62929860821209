import React, { Component } from 'react';
import Navbar from './../Component/Navbar';
import Cookies from 'universal-cookie';
import axios from 'axios';
import swal from 'sweetalert';

class Napthe extends Component {

    constructor(props) {
        super(props);
        document.title = "Nạp thẻ";

        this.state = {
            'type': 0,
            'amount': 0,
            'seri': '',
            'mathe': '',
            'load': false,
            'nhamang': [],
            'menhgias': [],
            'lichsunapthe': [],
        };

        this.ChangeType = this.ChangeType.bind(this);
        this.ChangeAmount = this.ChangeAmount.bind(this);
        this.ChangeSeri = this.ChangeSeri.bind(this);
        this.ChangeMathe = this.ChangeMathe.bind(this);
        this.Nap = this.Nap.bind(this);

    }

    componentDidMount(){
        var url = 'get_nha_mang.php';
        var data = {};

        const cookies = new Cookies();

        var token = 'null';

        if (!cookies.get('token')) {
            token = 'null';
        } else {
            token = cookies.get('token');
        };


        const headers = { Authorization: `${token}` };


        axios.post (process.env.REACT_APP_API + url, data,  { headers })
        .then(response => {
            console.log(response);
            var nhamang = response.data.data.nhamang;
            this.setState({
                nhamang: nhamang,
            });

        });


        var url = 'get_menh_gia.php';

        axios.post (process.env.REACT_APP_API + url, data,  { headers })
        .then(response => {
            console.log(response);
            var menhgias = response.data.data.menhgias;
            this.setState({
                menhgias: menhgias,
            });

        });

        var url = 'lichsunapthe.php';
        var data = {};

        axios.post (process.env.REACT_APP_API + url, data,  { headers })
        .then(response => {
            if ( response.data.status ) {
                var lichsunapthe = response.data.data.lichsunapthe;
                console.log (lichsunapthe);
                this.setState({
                    lichsunapthe: lichsunapthe,
                });
            }

        });
    }

    ChangeType(event){
        this.setState({type: event.target.value});
    }

    ChangeAmount(event){
        this.setState({amount: event.target.value});
    }

    ChangeSeri(event){
        this.setState({seri: event.target.value});
    }

    ChangeMathe(event){
        this.setState({mathe: event.target.value});
    }

    Nap(event){
        //
        this.setState({
            load: true,
        });

        const cookies = new Cookies();

        var token = 'null';

        if (!cookies.get('token')) {
            token = 'null';
        } else {
            token = cookies.get('token');
        };


        const headers = { Authorization: `${token}` };


        var url = 'napthe.php';
        var data = {
            'type': this.state.type,
            'amount': this.state.amount,
            'seri': this.state.seri,
            'mathe': this.state.mathe,
        };

        axios.post (process.env.REACT_APP_API + url, data,  { headers })
        .then(response => {

            var url = 'lichsunapthe.php';
            var data = {};
    
            axios.post (process.env.REACT_APP_API + url, data,  { headers })
            .then(response => {
                if ( response.data.status ) {
                    var lichsunapthe = response.data.data.lichsunapthe;
                    console.log (lichsunapthe);
                    this.setState({
                        lichsunapthe: lichsunapthe,
                    });
                }
    
            });

            console.log(response);
            this.setState({
                load: false,
            }); 

            var callback = response.data; 
            
            if ( callback['status'] == true ) {
                var type = 'success';
                this.setState({
                    next: true,
                }); 
            } else {
                var type = 'error';
            }

            var message = callback['message'];


            swal("Thông báo", message, type);
        });


        event.preventDefault();
    }

    render() {

        var list_type = this.state.nhamang;

        var dem = 0;
        var list_type = list_type.map((data) =>
            <option key={dem = dem + 1} value={data}>{data}</option>
        );

        var list_type2 = this.state.menhgias;

        var dem2 = 0;
        var list_type2 = list_type2.map((data) =>
            <option key={dem2 = dem2 + 1} value={data.menhgia}>{data.menhgia2} VNĐ</option>
        );

        var lichsunapthe = this.state.lichsunapthe;

        var dem3 = 0;
        var lichsunapthe = lichsunapthe.map((data) =>
        <tr key={dem3 = dem3 + 1}>
            <th scope="row">{data.id}</th>
            <td>{data.loaithe}</td>
            <td>{data.menhgia2} VNĐ</td>
            <td>{data.serial}</td>
            <td>{data.mathe}</td>
            <td>{data.time2}</td>
            <td>
                <button
                    className={
                        data.status == 0 ? 'text-light btn btn-warning' :
                        data.status == 1 ? 'text-light btn btn-success' :
                        data.status == 2 ? 'text-light btn btn-danger' : ''
                    }
                >{
                    data.status == 0 ? 'Chờ duyệt' :
                    data.status == 1 ? 'Thành công' :
                    data.status == 2 ? 'Thẻ sai' : ''
                }</button></td>
        </tr>
        );

        return (
            <div>
                <Navbar />
                <div className="py-5"></div>
                <div className="py-4"></div>
                <div className="container">
                    <div className="bg-light py-5 rounded">
                        <div className="row">

                            <div className="col-12 col-md-4 mb-5">
                                <div className="container">
                                    <form onSubmit={this.Nap}>
                                        <select className="form-select mb-3" value={this.state.type} onChange={this.ChangeType}>
                                            <option value="0">Chọn loại thẻ:</option>
                                            {list_type}
                                        </select>

                                        <select className="form-select mb-3" value={this.state.amount} onChange={this.ChangeAmount}>
                                            <option value="0">Chọn mệnh giá:</option>
                                            {list_type2}
                                        </select>

                                        <input value={this.state.seri} onChange={this.ChangeSeri} className="form-control mb-3" placeholder="Nhập serial" />
                                        <input value={this.state.mathe} onChange={this.ChangeMathe} className="form-control mb-3" placeholder="Nhập mã thẻ" />
                                        {
                                            this.state.load
                                            ?
                                            <button className="btn btn-info text-light w-100pt" disabled>
                                                Nạp thẻ
                                                <span className="px-1"></span>
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="sr-only"></span>
                                                </div>
                                            </button>
                                            :
                                            <button className="btn btn-info text-light w-100pt">Nạp thẻ</button>
                                        }
                                    </form>
                                </div>
                            </div>
                            
                            <div className="col-12 col-md-8 mb-5">
                                <div className="container">
                                    <div className="table-responsive">
                                        <table className="table table-striped rounded table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                <th scope="col">ID</th>
                                                <th scope="col">Loại thẻ</th>
                                                <th scope="col">Mệnh giá</th>
                                                <th scope="col">Serial</th>
                                                <th scope="col">Mã thẻ</th>
                                                <th scope="col">Ngày nạp</th>
                                                <th scope="col">Trạng thái</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {lichsunapthe}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Napthe;