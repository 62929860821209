import React, { Component } from 'react';
import Navbar from './../Component/Navbar';
import Cookies from 'universal-cookie';
import axios from 'axios';
import swal from 'sweetalert';

class Napthe extends Component {

    constructor(props) {
        super(props);
        document.title = "Rút tiền";

        this.state = {
            'type': 0,
            'amount': '',
            'seri': '',
            'mathe': '',
            'load': false,
            'nhamang': [],
            'menhgias': [],
            'lichsunapthe': [],
            'cong': 0,
            'stk': '',
            'lichsurut': [],
        };

        this.ChangeCong = this.ChangeCong.bind(this);
        this.ChangeAmount = this.ChangeAmount.bind(this);
        this.ChangeSTK = this.ChangeSTK.bind(this);
        this.Rut = this.Rut.bind(this);

    }

    componentDidMount(){
        this.LichSuRut();
    }

    ChangeCong(event){
        this.setState({cong: event.target.value});
    }

    ChangeAmount(event){
        this.setState({amount: event.target.value});
    }

    ChangeSTK(event){
        this.setState({stk: event.target.value});
    }

    Rut(event){
        this.setState({load: true});

        const cookies = new Cookies();

        var token = 'null';
    
        if ( !cookies.get('token') ) {
            token = 'null'
        } else {
            token = cookies.get('token');
        };

       //
       var url = 'ruttien.php';
       var data = {
        'cong': this.state.cong,
        'stk': this.state.stk,
        'amount': this.state.amount,
       };

       const headers = { Authorization: `${token}` };

       axios.post (process.env.REACT_APP_API + url, data, { headers } )
       .then(response => {
            this.setState({load: false});
           var content = response.data;

            if (content.status == true){
                this.LichSuRut();
                var type = 'success';
                swal("Thông báo", content.message, type);
                this.setState(
                    {
                        next: true,
                    }
                );

            } else {
                var type = 'error';
                swal("Thông báo", content.message, type);
            }
       });
        event.preventDefault();
    }

    LichSuRut(){

        const cookies = new Cookies();

        var token = 'null';
    
        if ( !cookies.get('token') ) {
            token = 'null'
        } else {
            token = cookies.get('token');
        };

       //
       var url = 'lichsurut.php';
       var data = {

       };

       const headers = { Authorization: `${token}` };

       axios.get (process.env.REACT_APP_API + url, { headers } )
       .then(response => {
           var content = response.data;
           this.setState({
               'lichsurut': content.data.lichsu,
           });

        //    console.log (this.state.lichsurut);

       });
    }

    render() {

        var listds = this.state.lichsurut;
        var dem = -1;
        var listds = listds.map((data) =>
            <tr key={dem = dem + 1}>
              <th scope="row">{data.id}</th>
              <th scope="row">{data.amount2 + 'đ'}</th>
              <th scope="row">{data.congthanhtoan}</th>
              <th scope="row">{data.stk}</th>
              <th scope="row">{data.time2}</th>
              <th scope="row">{data.status2}</th>
            </tr>
        );

        return (
            <div>
                <Navbar />
                <div className="py-5"></div>
                <div className="py-4"></div>
                <div className="container">
                    <div className="bg-light py-5 rounded">
                        <div className="row">

                            <div className="col-12 col-md-4 mb-5">
                                <div className="container">
                                    <form onSubmit={this.Rut}>
                                        <select className="form-select mb-3" value={this.state.cong} onChange={this.ChangeCong}>
                                            <option value="0">Chọn cổng thanh toán:</option>
                                            <option value="1">Momo</option>
                                        </select>

                                        <input value={this.state.stk} onChange={this.ChangeSTK} className="form-control mb-3" placeholder="Nhập số tài khoản" />
                                        <input value={this.state.amount} onChange={this.ChangeAmount} className="form-control mb-3" placeholder="Nhập số tiền" />

                                        {
                                            this.state.load
                                            ?
                                            <button className="btn btn-info text-light w-100pt" disabled>
                                                Rút tiền
                                                <span className="px-1"></span>
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="sr-only"></span>
                                                </div>
                                            </button>
                                            :
                                            <button className="btn btn-info text-light w-100pt">Rút tiền</button>
                                        }
                                    </form>
                                </div>
                            </div>
                            
                            <div className="col-12 col-md-8 mb-5">
                                <div className="container">
                                    <div className="table-responsive">
                                        <table className="table table-striped rounded table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                <th scope="col">ID</th>
                                                <th scope="col">Số tiền</th>
                                                <th scope="col">Cổng</th>
                                                <th scope="col">STK</th>
                                                <th scope="col">Ngày nạp</th>
                                                <th scope="col">Trạng thái</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { listds }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Napthe;