import React, { Component } from 'react';

import Navbar from './../Component/Navbar';
import PostCard from './../Component/PostCard'; 

import ThumbnailTaoTrangWeb from './../../Asset/Image/thumbnail-taotrangewb.png';
import ThumbnailSourceCode from './../../Asset/Image/thumbnail-sourcecode.svg';
import ThumbnailMien from './../../Asset/Image/thumbnail-mien.png';
import Thumbnail from './../../Asset/Image/thumb.png';
import Next from './../../Next';

class Home extends Component {
    constructor(props) {
        super(props);
        document.title = "Trang chủ";
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="container">
                    <center>
                        <div className="text-center">
                            <div className="py-5"></div>
                            <div className="py-4"></div>
                            <div className="">
                                <div>
                                    {/* <img src={Thumbnail} className="thumb rounded mb-4"></img> */}

                                    <div className="bg-menu py-4 rounded mb-5">
                                        <div className="text-left container-fluid">
                                            <h3 className="text-light">DỊCH VỤ WEB</h3>
                                        </div>
                                        <hr className="fix-hr rounded-pill" />
                                        <div className="py-2"></div>
                                        <div className="row">
                                            <div className="col-12 col-md-4">
                                                <div className="container mb-3">
                                                    <PostCard to="/tao-web" name="TẠO TRANG WEB" mota="Tạo trang web chỉ trong vài bước." img={ThumbnailTaoTrangWeb} />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="container mb-3">
                                                    <PostCard to="/mua-source" name="MUA SOURCE CODE" mota="Mua 1 source cực dễ và nhanh chóng." img={ThumbnailSourceCode} />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <div className="container mb-3">
                                                    <PostCard to="/mua-mien" name="MUA TÊN MIỀN" mota="Mua miền giá rẽ và nhanh chóng." img={ThumbnailMien} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </center>
                </div>
            </div>
        );
    }
}

export default Home;