import React, { Component } from 'react';
import Navbar from './../Component/Navbar';
import CardNap from './../Component/CardNap';
import ImgCard from './../../Asset/Image/imgcard.png';
import ImgTSR from './../../Asset/Image/ImgTSR.png';

class Naptien extends Component {

    constructor(props) {
        super(props);
        document.title = "Nạp tiền";

        this.state = {

        };

    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="py-5"></div>
                <div className="py-4"></div>
                <div className="container">
                    <div className="bg-light py-4 rounded">
                        <h3 className="container">NẠP TIỀN</h3>
                        <hr className="mb-4" />

                        <div>
                            <div className="row">
                                <div className="col-12 col-md-3 mb-3">
                                    <div className="container">
                                        <CardNap img={ImgCard} name="Nạp thẻ cào" slug="the-cao" />
                                    </div>
                                </div>

                                {/* <div className="col-12 col-md-3 mb-3">
                                    <div className="container">
                                        <CardNap img={ImgTSR} name="Nạp ví TSR" slug="tsr" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default Naptien;