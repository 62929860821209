import React, { Component } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";

import axios from 'axios';
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import { Redirect } from 'react-router';

import Navbar from './../Component/Navbar';
import Next from './../../Next';
import Imgload from './../../Asset/Image/imgload.gif';

class CreateWebFrorm extends Component {
    constructor(props){
        super(props);
        this.props = props;
        document.title = "Tạo trang web";

        this.state = {
            'load': true,
            'thongtintheme': {
                'thongtintheme': 0,
            },
            'thanhtoan': [
                1,
                3,
                6,
                12,
                24,
                36
            ],
            'taikhoanadmin': '',
            'matkhauadmin': '',
            'tenmien': '',
            'thanhtoanweb': 0,
            'tienthanhtoan': 0,
            'clickload': false,
            'taosuccess': false,
            'timett': 0,
        }

        document.title = "Tạo trang website";
        this.params = this.props.params;

        this.ChangeTkAdmin = this.ChangeTkAdmin.bind(this);
        this.ChangeMkAdmin = this.ChangeMkAdmin.bind(this);
        this.ChangeDomain = this.ChangeDomain.bind(this);
        this.ChangeThanhToan = this.ChangeThanhToan.bind(this);
        this.TaoWeb = this.TaoWeb.bind(this);

    }

    componentDidMount(){
        var url = 'getthongtintheme.php';
        var data = {
            'id': this.params.id,
        };

        axios.post (process.env.REACT_APP_API + url, data)
        .then(response => {

            this.setState({
                load: false,
                thongtintheme: response.data.data.thongtintheme,
            });

        });
    }


    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    ChangeTkAdmin(event){
        this.setState({taikhoanadmin: event.target.value});
    }

    ChangeMkAdmin(event){
        this.setState({matkhauadmin: event.target.value});
    }

    ChangeDomain(event){
        this.setState({tenmien: event.target.value});
    }

    ChangeThanhToan(event){
        this.setState({
            thanhtoanweb: event.target.value,
            tienthanhtoan: event.target.value * this.state.thongtintheme['gia'],
        });

        if ( event.target.value == 0 ) {
            this.setState({
                timett: 0,
            });
        }

        if ( event.target.value == 1 ) {
            this.setState({
                timett: 1,
            });
        }

        if ( event.target.value == 2 ) {
            this.setState({
                timett: 3,
            });
        }


        if ( event.target.value == 3 ) {
            this.setState({
                timett: 6,
            });
        }


        if ( event.target.value == 4 ) {
            this.setState({
                timett: 12,
            });
        }

        if ( event.target.value == 5 ) {
            this.setState({
                timett: 24,
            });
        }

        if ( event.target.value == 6 ) {
            this.setState({
                timett: 36,
            });
        }

    }

    TaoWeb(event){
        this.setState({clickload: true});

        var url = 'taoweb.php';
        var data = {
            'theme': this.params.id,
            'tkadmin': this.state.taikhoanadmin,
            'mkadmin': this.state.matkhauadmin,
            'domain': this.state.tenmien,
            'thanhtoan': this.state.thanhtoanweb,
            'timett': 0,
        }

        const cookies = new Cookies();

        var token = 'null'

        if ( !cookies.get('token') ) {
            token = 'null'
        } else {
            token = cookies.get('token');
        };


        const headers = { Authorization: `${token}` };


        axios.post (process.env.REACT_APP_API + url, data, { headers } )
        .then(response => {
            this.setState({clickload: false});
          this.setState({load: false});


          var content = response.data;

          if (content.status == true){
              var type = 'success';
              swal("Thông báo", content.message, type);
              this.sleep(1000).then(() => {
                    this.setState({taosuccess: true});
              });
          } else {
              var type = 'error';
              swal("Thông báo", content.message, type);
          }

        })
        .catch(error => console.log(error));



       event.preventDefault();
    }




    render () {
            var thanhtoan = this.state.thanhtoan;
            var dem = 0;
            var listItems = thanhtoan.map((data) =>
                <option key={dem + 1} value={dem = dem + 1}>{data} Tháng / { data * this.state.thongtintheme['gia'] } VNĐ</option>
            );

        return (
            <div>
                {this.state.taosuccess ? <Redirect to="/profile/lich-su-tao-web" />  :  ''}
                <Navbar />
                <div className="py-5"></div>
                <div className="py-3"></div>

                <div className="container mb-5">
                    <div className="bg-main py-4 rounded container">
                        <div className="row">
                            <div className="col-12 col-md-7 mb-3 border-right border-dark">
                                <form onSubmit={this.TaoWeb}>
                                    <label className="text-danger text-bold">Tài khoản Admin:</label>
                                    <input className="form-control mb-4" placeholder="Nhập tài khoản Admin" value={this.state.taikhoanadmin} onChange={this.ChangeTkAdmin}></input>

                                    <label className="text-danger text-bold">Mật khẩu Admin:</label>
                                    <input className="form-control mb-4" placeholder="Nhập mật khẩu Admin" value={this.state.matkhauadmin} onChange={this.ChangeMkAdmin}></input>

                                    <label className="text-danger text-bold">Tên miền:</label>
                                    <input className="form-control mb-4" placeholder="Nhập tên miền (url website)" value={this.state.tenmien} onChange={this.ChangeDomain}></input>

                                    <label className="text-danger text-bold">Thanh toán:</label>
                                    <select className="form-control mb-4" onChange={this.ChangeThanhToan} value={this.state.thanhtoanweb}>
                                        <option value="0">Thanh toán:</option>
                                        { this.state.load ? '' : listItems }
                                    </select>

                                    { this.state.clickload ?
                                    <button className="btn btn btn-danger w-100pt" disabled>
                                        <span className="xtext">
                                            TẠO NGAY - {this.state.tienthanhtoan} VNĐ

                                            <span className="px-1"></span>
                                             <div className="spinner-border spinner-border-sm" role="status">
                                                 <span className="sr-only"></span>
                                             </div>

                                        </span>
                                    </button>
                                    :
                                    <button className="btn btn-danger w-100pt">
                                        <span className="xtext">
                                            TẠO NGAY - {this.state.thongtintheme['gia'] * this.state.timett } VNĐ
                                        </span>
                                    </button>
                                    }

                                </form>
                            </div>
                            <div className="col-12 col-md-5 mb-3">
                                <div className="text-center">
                                    <div className="img-show-fix mb-3">
                                        {
                                            this.state.load ? <img src={Imgload} className="img-show rounded bg-dark"></img> : <img src={this.state.thongtintheme['thumb']} className="img-show rounded"></img>
                                        }
                                    </div>

                                    {
                                        this.state.load ?
                                         <button type="submit" className="btn btn-primary btn btn-buy text-light w-100pt" disabled>
                                            XEM DEMO
                                            <span className="px-1"></span>
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </button>
                                        :
                                        <a href={this.state.thongtintheme['linkdemo']} target="_bank">
                                            <button className="btn btn-primary btn-buy text-light w-100pt">XEM DEMO</button>
                                        </a>
                                    }

                                    <hr />
                                </div>

                                <ul>
                                    <li><span className="text-primary text-bold">MÃ SỐ: </span>
                                        {
                                            this.state.load ?
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                     <span className="sr-only"></span>
                                                 </div>
                                             : this.state.thongtintheme['id']
                                        }
                                     </li>

                                    <li><span className="text-primary text-bold">TÊN GIAO DIỆN: </span>
                                        {
                                            this.state.load ?
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                     <span className="sr-only"></span>
                                                 </div>
                                             : this.state.thongtintheme['ten']
                                        }
                                     </li>

                                    <li><span className="text-primary text-bold">GIÁ TIỀN: </span>
                                        {
                                            this.state.load ?
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                     <span className="sr-only"></span>
                                                 </div>
                                             : this.state.thongtintheme['giathuc'] + ' VNĐ'
                                        }
                                     </li>

                                    <li><span className="text-primary text-bold">MÔ TẢ: </span>
                                    {
                                        this.state.load ?
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                 <span className="sr-only"></span>
                                             </div>
                                         : this.state.thongtintheme['mota']
                                    }
                                    </li>
                                </ul>
                                <div className="pỵ-5 mb-5"></div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );

    };
}

export default (props) => (
   <CreateWebFrorm
       params={useParams()}
   />
);