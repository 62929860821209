import React, { Component } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

class CardSp extends Component {
    render() {
        return (
            <div>
                <div className="border rounded bg-main shadow-sm mb-3">
                    <div className="">
                        <img src={this.props.img} className="w-100pt rounded-top border-bottom mb-3 img-thumb"></img>
                    </div>

                    <div className="text-center">
                        <p className="text-uppercase text-bold d-inline-block text-truncate text-hidden">{this.props.name}</p>
                        <Link to={'/nap-tien/' + this.props.slug}>
                            <button className="btn btn-info text-light w-100pt no-border btn-buy rounded-bottom">Nạp ngay</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default CardSp;