import React, { Component } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import Navbar from './../Component/Navbar';
import CardSp from './../Component/CardSp';
import Next from './../../Next';
import IconLoad from './../../Asset/Image/loading.gif';
import axios from 'axios';

class CreateWeb extends Component {

    constructor(props){
        super(props);
        document.title = "Kho giao diện";

        this.state = {
            'load': true,
            'danhsachtheme': [],
        }
    }

    componentDidMount(){
        var url = 'getthemeweb.php';
        var data = {};

        axios.post (process.env.REACT_APP_API + url, data)
        .then(response => {
            var danhsachtheme = response.data.data.danhsachtheme;
            //console.log(danhsachtheme);
            this.setState({
                load: false,
                danhsachtheme: danhsachtheme,
            });

        });
    }



    render() {

        var danhsachtheme = this.state.danhsachtheme;
        var listItems = danhsachtheme.map((data) =>
            <div key={data.id} className="col-12 col-md-3 mb-2">
                <CardSp id={data.id} img={data.thumb} name={data.ten} />
            </div>
        );

        var Danhsachtheme =
          <div className="row">
            {listItems}
          </div>
        ;

        var Loading =
            <div className="text-center">
                <img className="iconload" src={IconLoad}></img>
            </div>
        ;

        return (
            <div>
                <Navbar />
                <div className="py-5"></div>
                <div className="py-3"></div>
               <div className="container mb-5">
                    <div className="rounded bg-main py-5 container maindanhsach">
                        <h2 className="text-center text-danger text-bold">MẪU WEB THIẾT KẾ</h2>
                        <hr className="bg-danger" />
                        {
                            this.state.load ? Loading : Danhsachtheme
                        }
                    </div>
               </div>
            </div>
        );
    }
}

export default CreateWeb;