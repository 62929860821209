import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Cookies from 'universal-cookie';
import axios from 'axios';



class Next extends Component {

    constructor(props){
        super(props);
        this.props = props;
        this.state = {
            next: '',
        }
    }

    componentDidMount(){
        var level = this.props.level;

        const cookies = new Cookies();

        //cookies.set('token', '');
        // cookies.remove("token");

        if ( level == '1' ) {

        }
    
        if ( level == '2' ) {    
                var url = 'checktoken.php';
                var data = {};
    
                // Check token đúng hay sai
                const cookies = new Cookies();
    
                var token = 'null';
            
                if ( !cookies.get('token') ) {
                    token = 'null';
                } else {
                    token = cookies.get('token');
                };
                
                const headers = { Authorization: `${token}` };
            
                axios.post (process.env.REACT_APP_API + url, data, { headers } )
                .then(response => { 
                    var content = response.data;
                    if ( content.status == false ) {
                        cookies.remove("token");
                        this.setState({next: '/dang-nhap'});
                    }
            
                })
                .catch(error => console.log(error));
                
        }
    
        if ( level == '3' ) {
                var url = 'checktoken.php';
                var data = {};
    
                // Check token đúng hay sai
                const cookies = new Cookies();
    
                var token = 'null'
            
                if ( !cookies.get('token') ) {
                    token = 'null'
                } else {
                    token = cookies.get('token');
                };
                
                const headers = { Authorization: `${token}` };
            
                axios.post (process.env.REACT_APP_API + url, data, { headers } )
                .then(response => { 
                    var content = response.data;
                    if ( content.status == false ) {
                        cookies.remove("token");
                        this.setState({next: '/dang-nhap'});
                    } else {
                        var data_api = content.data.infouser;
                        //console.log(data_api.quyen);

                        if ( data_api.quyen == 9 ) {
                        
                        } else {
                            cookies.remove("token");
                            this.setState({next: '/dang-nhap'});
                        }
                    }

            
                })
                .catch(error => console.log(error));
            
        }
    }

    render() {

        return(
            <div>
                { this.state.next == '' ? '' : <Redirect to={this.state.next} />}
            </div>
        );
    
    }
}

export default Next;