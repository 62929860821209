import React, { Component } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from "react-router-dom";

import axios from 'axios';
import Cookies from 'universal-cookie';
import Navbar from './../Component/Navbar';

class LichSuMuaSource extends Component {
    constructor(props) {
        super(props);
        document.title = "Lịch sử tạo web";

        this.state = {
            'listds': [],
        }
    }

    componentDidMount(){
        var url = 'lichsumuasource.php';
        var data = {};

        const cookies = new Cookies();

        var token = 'null';

        if (!cookies.get('token')) {
            token = 'null';
        } else {
            token = cookies.get('token');
        };


        const headers = { Authorization: `${token}` };


        axios.post (process.env.REACT_APP_API + url, data,  { headers })
        .then(response => {
            var listds = response.data.data.danhsachweb;
            this.setState({
                listds: listds,
            });

        });
    }

    render() {


        var listds = this.state.listds;
        var dem = -1;
        var listds = listds.map((data) =>
            <tr key={dem = dem + 1}>
              <th scope="row">{data.data.id}</th>
              <td>{data.data.ten}</td>
              <td>{data.data.thanhtoan} VNĐ</td>
              <td>{data.data.ngaymua}</td>
              <td><a target="_bank" href={data.data.demo}><button className="btn btn-info text-light w-100pt">Demo</button></a></td>
              <td><a target="_bank" href={data.data.download}><button className="btn btn-danger w-100pt">Download</button></a></td>
            </tr>
        );

        return (
            <div>
                <Navbar />
                <div className="py-5"></div>
                <div className="py-4"></div>
                <div className="container">
                    <div className="bg-table rounded">
                        <table className="table table-light table-striped rounded table-hover table-bordered">
                        <thead>
                            <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Tên</th>
                            <th scope="col">Giá</th>
                            <th scope="col">Ngày mua</th>
                            <th scope="col">Demo</th>
                            <th scope="col">Download</th>
                            </tr>
                        </thead>
                        <tbody>
                                { listds }
                        </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }
}

export default LichSuMuaSource;